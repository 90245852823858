@charset "utf-8";



// VARIABLES

$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

// Colors

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Media

$content-width:    800px;
$on-palm:          600px;
$on-laptop:        800px;

// Generic

$spacing-unit:     30px;



// SELECTORS

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}



// MIXINS

//  Use media queries like this:
//  
//  @include media-query($on-palm) {
//    .wrapper { padding-right: $spacing-unit / 2; }
//  }

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
  "base",
  "layout",
  "component",
  "syntax-highlighting"
;