@font-face {
  font-family: 'feather';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/feather/feather.eot?1gafuo');
  src: url('../fonts/feather/feather.eot?1gafuo#iefix') format('embedded-opentype'), url('../fonts/feather/feather.woff2?1gafuo') format('woff2'), url('../fonts/feather/feather.ttf?1gafuo') format('truetype'), url('../fonts/feather/feather.woff?1gafuo') format('woff'), url('../fonts/feather/feather.svg?1gafuo#feather') format('svg');
}

.icon {
  font-family: 'feather';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.icon--arrow-left:before {
  content: '\e901';
}

.icon--menu:before {
  content: '\e903';
}

.icon--cross:before {
  content: '\e117';
}


/* Menu styles */

.menu {
  position: fixed;
  top: 70px;
  left: 0;
  width: 300px;
  height: calc(100vh - 70px);
  background: #1c1d22;
}

.menu__wrap {
  position: absolute;
  top: 3.5em;
  bottom: 0;
  overflow: hidden;
  width: 100%;
}

.menu__level {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  overflow-y: scroll;
  width: calc(100% + 50px);
  height: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 3em;
  list-style-type: none;
}

.menu__level--current {
  visibility: visible;
}

.menu__item {
  display: block;
  width: calc(100% - 50px);
}

.menu__link {
  font-weight: 400;
  position: relative;
  display: block;
  padding: .5em 2.5em .5em 1.5em;
  color: #bdbdbd;
  -webkit-transition: color 0.1s;
  transition: color 0.1s;
}

.menu__link[data-submenu]::after {
  content: '\e904';
  font-family: 'feather';
  position: absolute;
  right: 0;
  padding: 0.25em 1.25em;
  color: #2a2b30;
}

.menu__link:hover,
.menu__link[data-submenu]:hover::after {
  color: #5c5edc;
}

.menu__link--current::before {
  content: '\00B7';
  font-size: 3.5em;
  line-height: 0;
  position: absolute;
  top: 50%;
  left: 0.12em;
  height: 4px;
  color: #5c5edc;
}

[class^='animate-'],
[class*=' animate-'] {
  visibility: visible;
}

.animate-outToRight .menu__item {
  -webkit-animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.animate-outToLeft .menu__item {
  -webkit-animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.animate-inFromLeft .menu__item {
  -webkit-animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate-inFromRight .menu__item {
  -webkit-animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.menu__breadcrumbs {
  font-size: 0.65em;
  line-height: 1;
  position: relative;
  padding: 2.5em 3.75em 1em 2.5em;
}

.menu__breadcrumbs a {
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #bdbdbd;
}

.menu__breadcrumbs a:last-child {
  pointer-events: none;
}

.menu__breadcrumbs a:hover {
  color: #8182e0;
}

.menu__breadcrumbs a:not(:last-child)::after {
  content: '\e902';
  font-family: 'feather';
  display: inline-block;
  padding: 0 0.5em;
  color: #33353e;
}

.menu__breadcrumbs a:not(:last-child):hover::after {
  color: #33353e;
}

.menu__back {
  font-size: 1.05em;
  position: absolute;
  z-index: 100;
  top: 0;
  right: 2.25em;
  margin: 0;
  padding: 1.365em 0.65em 0 0;
  cursor: pointer;
  color: #2a2b30;
  border: none;
  background: none;
}

.menu__back--hidden {
  pointer-events: none;
  opacity: 0;
}

.menu__back:hover,
.menu__back:focus {
  color: #fff;
  outline: none;
}


/* Open and close buttons */

.action {
  position: absolute;
  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;
}

.action:focus {
  outline: none;
}

.action--open {
  font-size: 1.5em;
  top: 1em;
  left: 1em;
  display: none;
  color: #fff;
  position: fixed;
  z-index: 1000;
}

.action--close {
  font-size: 1.1em;
  top: 1.25em;
  right: 1em;
  display: none;
  color: #45464e;
}

@media screen and (max-width: 50em) {
  .action--open,
  .action--close {
    display: block;
  }
  .menu {
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100vh;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
  }
  .menu--open {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
